import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import Plan from '../client/plan';
import Shopper from '../client/shopper';
import PaymentForm from '../components/bluesnap-payment-form';
import PlanPurchasedModal from '../dialogs/plan-purhcased-modal';
import { AppContext } from '../lib/app-context';
import { shouldLoginState } from '../state/local';
import { trackEvent } from '../utils/analityics.utils';




/**
 *
 * @param {object} props
 * @param {Plan} props.plan
 * @param {boolean} props.subscribed
 * @param {Function} props.onSelect
 * @returns
 */
function PurchaseButton({ plan, subscribed, onSelect }) {
  const { user } = React.useContext(AppContext);
  if (plan.status === Plan.STATUS.INACTIVE) {
    return <Button id={`btn-select-${plan.sid}`} className="btn-purchase-inactive btn-purchase" onClick={onSelect}>Save your spot</Button>
  }
  if (plan.recurringPrice) {
    return <Button id={`btn-select-${plan.sid}`} className="btn-purchase-active btn-purchase" onClick={onSelect}>Start Your Free Trial</Button>
  }

  if (user) {
    return <Button id={`btn-select-${plan.sid}`} className="btn-purchase-free btn-purchase" onClick={onSelect}>{subscribed ? 'Downgrade' : 'Start now for free'}</Button>
  }

  return <Button id={`btn-select-${plan.sid}`} className="btn-purchase-free btn-purchase" onClick={() => document.location = '/'}>Start now for free</Button>
}

/**
 * @param {object} props
 * @param {Plan} props.plan
 * @param {boolean} props.selected
 * @param {boolean} props.subscribed
 * @param {Function} props.onSelect
 * @returns
 */
function PlanCard({ plan, selected, subscribed, onSelect }) {
  return <div className={'plan col ' + (selected ? 'selected ' : '') + (plan.status === Plan.STATUS.INACTIVE ? 'inactive' : '')}>
    {selected && <div className="selected-plan-text">Your Plan</div>}
    <div className="plan-content">
      <div className="plan-name gradient-text">{plan.name}</div>
      {
        plan.status !== Plan.STATUS.INACTIVE
          ? <div className="plan-price active" ><span className="gradient-text">${plan.overrideRecurringPrice || plan.recurringPrice}</span><span className="timeframe">per month</span></div>
          : <div className="plan-price inactive">Coming<br />soon</div>
      }
      {
        plan.overrideRecurringPrice ? <div className="plan-original-price"><span className="linethrough">${plan.recurringPrice}</span></div> : ''
      }
      {/* <div className="plan-description" dangerouslySetInnerHTML={{__html: plan.description}} /> */}
      <div className="plan-purchase">
        {!selected && <PurchaseButton subscribed={subscribed} onSelect={onSelect} plan={plan} />}
      </div>
      <div className="plan-videos">Unlimited</div>
      <div className="plan-maxVideoDuration">{plan.maxVideoDuration}</div>
      <div className="plan-subtitles">Yes</div>
      <div className="plan-customWatermark">Yes</div>
      <div className="plan-stockFootage">Yes</div>
      <div className="plan-brandsCount">{plan.brandsCount > 1 ? (plan.brandsCount + ' Brands') : (plan.brandsCount === 1 ? '1 Brand' : 'Unlimited brands')}</div>
      <div className="plan-maxStorage">{plan.maxStorage ? `${plan.maxStorage} GB cloud storage` : 'Unlimited'}</div>
    </div>
  </div>
}

/**
 *
 * @param {
 *  onHide: Function
 * }} props
 * @returns
 */
function ComingSoon({ onHide }) {
  return <Modal
    show={true}
    onHide={onHide}
    id="soon-dialog"
    className="soon"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Body>Your spot is saved!</Modal.Body>
  </Modal>
}

var loadedPlans = []


export default function Plans() {

  /**
   * @type {Shopper[]}
   */
  const [shopper, setShopper] = React.useState();
  const [changeSuccess, setChangeSuccess] = React.useState(false);

  /**
   * @type {Plan[]}
   */
  const [plans, setPlans] = React.useState([]);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [showComingSoon, setShowComingSoon] = React.useState(false);


  const { user, plan, setPlan, } = React.useContext(AppContext)

  React.useEffect(async () => {
    if (!loadedPlans.length) {
      const items = await Plan.list(null, null, {
        sid: true,
        status: true,
        name: true,
        recurringPrice: true,
        overrideRecurringPrice: true,
        annualRecurringPrice: true,
        annualOverrideRecurringPrice: true,
        description: true,
        freeTrial: true,
        maxVideoDuration: true,
        maxVideos: true,
        maxUsers: true,
        maxStorage: true,
        brandsCount: true,
        provider: true,
        planId: true,
        annualPlanId: true,
        showShutterStockBadge: true
      });
      items.sort((a, b) => a.recurringPrice - b.recurringPrice)
      loadedPlans = items
    }
    setPlans(loadedPlans)
  }, []);

  React.useEffect(async () => {
    if (user) {
      const shopper = await Shopper.get(null);
      setShopper(shopper);
    }
  }, [user]);

  /**
   * @param {Plan} plan
   */
  async function onPlanSelected(plan) {
    if (!user) {
      setSelectedPlan(plan)
      return shouldLoginState.set(true)
    }
    if (plan.status === Plan.STATUS.INACTIVE) {
      await plan.select();

      trackEvent('plan-request', {
        planSid: plan.sid,
        planName: plan.name,
        planPrice: plan.overrideRecurringPrice || plan.recurringPrice,
      });
      return setShowComingSoon(true)
    }
    if (shopper || plan.recurringPrice === 0) {
      await plan.select()

      trackEvent('plan-select', {
        planSid: plan.sid,
        planName: plan.name,
        planPrice: plan.overrideRecurringPrice || plan.recurringPrice,
      });
      setChangeSuccess(true)
      return setPlan(plan);
    }
    setSelectedPlan(plan)
  }

  async function onPurchaseSuccess(subscriptionDetailts) {
    await selectedPlan.select(subscriptionDetailts)

    trackEvent('plan-select', {
      planSid: selectedPlan.sid,
      planName: selectedPlan.name,
      planPrice: selectedPlan.overrideRecurringPrice || selectedPlan.recurringPrice,
    });
    setPlan(selectedPlan)
    setChangeSuccess(true)
    setSelectedPlan(null)
  }

  return <>
    {changeSuccess && <PlanPurchasedModal onHide={() => setChangeSuccess(false)} sequenceSid={document.location.hash && document.location.hash.substr(1)} />}
    {showComingSoon && <ComingSoon onHide={() => setShowComingSoon(false)} />}

    {selectedPlan && user &&
      <Modal
        show={true}
        onHide={() => setSelectedPlan(null)}
        backdrop="static"
        keyboard={false}
        className="purchase"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <PaymentForm
            title='Payment details'
            buttonText="Confirm Purchase"
            shopper={new Shopper()}
            onSuccess={onPurchaseSuccess}
          />
        </ModalBody>
      </Modal>
    }
  </>
}