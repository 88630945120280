import { Link } from 'gatsby';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';

import '../../css/styles.scss'
import { AppContext } from '../lib/app-context';
import { sequencesSinceLastChargeState } from '../state/local';
import CreateErrorDialog from './CreateErrorDialog';
import Upgrade from './upgrade';
// import '../../css/support.scss'

/**
 * 
 * @param {{
 *  onHide: Function,
 *  sequenceSid: string,
 *  downgraded: boolean,
 * }} props
 * @returns 
 */

export default function PlanPurchaseModal({ onHide, downgraded, sequenceSid }) {
    const { user, plan, subscription } = React.useContext(AppContext)
    const sequencesSinceLastCharge = sequencesSinceLastChargeState.use()
    const [upgradePlan, setUpgradePlan] = React.useState(false)
    const [projectsLimitReached, setProjectsLimitReached] = React.useState(false)
    const [brandkitMissingModal, setBrandkitMissingModal] = React.useState(false)
    const [brandkitMissingLogo, setbrandkitMissingLogo] = React.useState(false)

    React.useEffect(() => getRestrictions, [plan, subscription])

    function getRestrictions() {
        
        if (isNaN(sequencesSinceLastCharge) || plan?.maxVideos <= sequencesSinceLastCharge || !plan) {
            return setProjectsLimitReached(plan.maxVideos <= sequencesSinceLastCharge || !plan)
        }
        if (!user?.preset) {
            return setBrandkitMissingModal(true)
        }
        if (!user.preset.getDefaultLogo()) {
            return setbrandkitMissingLogo(true)
        }
       
    }

    const restrictionsText = () => {
        switch (true) {
            case downgraded:
                return {
                    title: 'Even though you downgraded you can still create new projects',
                    button: 'Create a new project'
                }
            case projectsLimitReached:
                return {
                    title: 'Oops. something wen\'t wrong',
                    button: 'Close'
                }
            case brandkitMissingModal || brandkitMissingLogo:
                return {
                    title: 'Thank you for purchasing a plan, now please set up your first brand kit to get started',
                    button: 'Set up brand kit'
                }
        }
    }

    if (brandkitMissingModal || brandkitMissingLogo || projectsLimitReached) {
        return <CreateErrorDialog {...restrictionsText()} projectsLimitReached={projectsLimitReached} brandkitMissingLogo={brandkitMissingLogo} upgrade={true} onHide={onHide} />
    }

    return <Modal
        show={true}
        onHide={onHide}
        className="confirmation"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ paddingRight: 0, paddingLeft: 0, zIndex: '4200' }}
    >
        <ModalBody className="confirmation-modal-body">
            <button className='btn btn-close' onClick={onHide} id="btn-close-new-project" />
            <div className="panel panel-primary">
                <div className="panel-heading" style={{ paddingBottom: '20px' }}>
                    <h2 className="panel-title-plan">Thank you</h2>
                </div>
                {sequenceSid && (
                    <React.Fragment>
                        <div>
                            You can go ahead and continue working on your project now
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                            <Link to={`/project#${sequenceSid}`} id="btn-back-to-project" className="btn bg-rainbow btn-white">
                                Get back to your project
                            </Link>
                        </div>
                    </React.Fragment>
                )}
                {!sequenceSid && (
                    <React.Fragment>
                        <div>
                            Thank you for purchasing a plan, now go ahead and create a new project
                        </div>
                        <div className='btn-plans-container'>
                            <Link to='/project/' id="btn-new-project" className="btn-bg-secondary" data-cy="new-project">
                                Create a project
                            </Link>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </ModalBody>
    </Modal >
}